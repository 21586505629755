import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import LiteYoutubeEmbed from  '../../node_modules/lite-youtube-embed/src/lite-yt-embed.js';

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    // Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    });

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
